<template>
  <div class="course-page">
    <!-- <com-carousel
      :banners="indexCarousel"
      :fullWidth="true"
      trigger="click"
      height="550px"
    ></com-carousel> -->
    <div class="content">
      <div style="display: flex;justify-content: space-between;align-items: center;height: 50px;">
         <!-- 面包屑 -->
      <Breadcrumb></Breadcrumb>
      <div >
        <el-button type="primary" size="small" @click="myCreate" v-if="userInfo.type === 2">我管理的</el-button>
        <el-button type="primary" size="small" @click="myJoin" v-if="userInfo.type == 2 ||userInfo.type == 1 ">我学习的</el-button>
      </div>
      </div>
     
      <!-- 课 -->
      <div class="course-content-item">
        <div class="item-header" v-loading="cateLoding">
          <div class="item-header-left">
            <div class="box" v-for="(item, index) in catelist" :key="index">
              <div class="text_box">
                <div class="text_tab">
                  <span class="label">{{ item.label }}</span>
                  <el-tabs
                    v-model="item.tabActiveName"
                    @tab-click="handleClick(item.value, index)"
                    :stretch="true"
                  >
                    <el-tab-pane
                      :label="v.label"
                      :name="v.label"
                      v-for="(v, i) in typeList"
                      :key="i"
                    ></el-tab-pane>
                  </el-tabs>
                </div>
                <div
                  class="more"
                  v-href="{
                    query: { cid: item.value },
                    path: '/course/moreCourse',
                  }"
                  @click="
                    $handleRoute({ cid: item.value }, '/course/moreCourse')
                  "
                >
                  更多课程<i class="el-icon-arrow-right"></i>
                </div>
              </div>
              

              <el-row :gutter="30"  v-loading="listLoading" v-if="(item.dataList || []) && (item.dataList || []).length>0">
                <!-- <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <div class="img" v-if="item.list != ''">
                    <el-carousel trigger="click" height="320px">
                      <el-carousel-item
                        v-for="(item, index) in item.dataList"
                        :key="index"
                      >
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="item.cover"
                          @click="$handleRoute(item.target_url)"
                        ></el-image>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </el-col> -->
                <el-col
                  :xs="24"
                  :sm="12"
                  :md="8"
                  :lg="4"
                  :xl="4"
                  v-for="(j, k) in item.dataList"
                  :key="k"
                  
                  class="Course-item"
                >
                  <CourseItem :item="j"></CourseItem>
                </el-col>
              </el-row>
              <p class="nothing" v-else>
                暂无课程
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { carousel } from "@/api/common.js";

import { course_list, courseConfig } from "@/api/course.js";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb.vue";
import CourseItem from "@/components/CourseItem/CourseItem.vue";
import Banner from "@/components/banner/banner.vue";
import ComCarousel from "@/components/com/comCarousel";

export default {
  components: {
    ComCarousel,
    Breadcrumb,
    CourseItem,
    Banner,
  },
  data() {
    return {
      tabActiveName: "",
      courseLoding: false,
      listLoading: false,
      cateLoding: false,
      cateCourseList: [],
      cateTypeList: [],
      page: 1,
      page_size: 12,
      keyword: "",
      type: "",
      cate: "",
      catelist: [],
      typeList: [],
      indexCarousel: [],
      list: [],
      courseCarousel: [],
    };
  },
  created() {
    // this.getCarousel();
    this.getCourseConfig();
  },
  methods: {
    myCreate(){
      this.$handleRoute('myCreateList');
    },
    myJoin(){
      this.$handleRoute('myJoinList');
    },
    getCourse_list(type, cid, i) {
        this.listLoading=true
        course_list({
          page: this.page,
          page_size: this.page_size,
          pageSize: this.page_size,
          keyword: "",
          type,
          cid,
        }).then((res) => {
          this.listLoading=false
          // console.log(res, "Ressssssssssss");
          let data = (res.data || {}).data;
          this.$set(this.catelist[i], "dataList", data);
        });
    },
    handleClick(v, i) {
      console.log(this.typeList, "this.typeList");
      let type = this.typeList.filter((v) => {
        return this.catelist[i].tabActiveName == v.label;
      })[0].value;
      console.log(type, "cate");
      console.log(v, "v");
      this.getCourse_list(type, v, i);
    },
    // 课程推进轮播
    // getCarousel() {
    //   // let storageKey = "storageKey:api/v1_0_0.index/get_imgs"
    //   // let data = this.$storage.get(storageKey)
    //   // if (data) {
    //   //   console.log(data, "缓存")
    //   //   this.indexCarousel = data.list
    //   // }
    //   if (!this.$store.state.courseImg.length) {
    //     carousel({ type: 3 }).then(({ data }) => {
    //       this.indexCarousel = data.list;
    //       this.$store.state.courseImg = data.list;
    //     });
    //   } else {
    //     this.indexCarousel = this.$store.state.courseImg;
    //   }
    // },
    getTypeList() {
      this.courseLoding = true;
      course_list({
        page: this.page,
        page_size: this.page_size,
        keyword: this.keyword,
        type: this.type,
        cate: this.cate,
      })
        .then((res) => {
          this.courseLoding = false;

          this.cateTypeList = (res.data || {}).data;
        })
        .catch((error) => {
          this.courseLoding = false;
        });
    },
    // 筛选
    getCourseConfig() {
      this.cateLoding = true;
      courseConfig({})
        .then(({ data }) => {
          this.typeList = data.type.list;
          this.catelist = data.cate.list;
          // console.log(this.typeList,"this.typeList");
          // console.log(this.catelist,"this.catelist");
          this.catelist.map((v, i) => {
            // console.log(v,"vvvv");
            // console.log(v.value,"valuevaluevalue");
            v.tabActiveName = "";
            // v.tabActiveName = this.typeList[0].label
            this.getCourse_list(null, v.value, i);
            // this.getCourse_list(this.typeList[0].value , v.value ,i)
          });
        })
        .finally(() => {
          this.cateLoding = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
// .el-row {
//   padding: 3% 0;
// }
.item-body {
  min-height: 100px;
}
::v-deep {
  .el-input__inner:focus {
    border: 1px solid #dcdfe6;
  }
}
.text {
  text-align: center;
  margin: 2% 0;
  color: #999;
}
.course-content-item {
  // margin: 1rem 0;
  .item-header {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    min-height: 200px;

    margin-bottom: 1.5rem;

    .item-header-left {
      // display: flex;
      // justify-content: space-between;
      // flex-direction: column;
      // align-items: center;
      .box {
        // display: flex;
        // align-items: center;
        text-align: left;
        font-size: 18px;
        font-weight: 600;
        color: #3a3a3a;
        line-height: 48px;
        // min-height: 200px;
        &:nth-child(1) {
          margin-left: 0;
        }
        .text_box {
          display: flex;
          position: relative;
          align-items: center;
          justify-content: space-between;
          .text_tab {
            display: flex;
            .label {
              margin-right: 3rem;
            }
            // align-items:  center;
          }
          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3a3a3a;
            margin-left: 3%;
            cursor: pointer;
          }
        }
        .nothing {
          width: 100%;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999;
          text-align: center;
          margin-top: 2%;
          line-height: 20rem;
        }
      }

      .courseTitle {
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #3a3a3a;
        line-height: 33px;
        // margin: 0 1.5rem;
      }
    }
  }
  .more {
    position: absolute;
    right: 0;
    top: 0;
    color: rgba(30, 30, 30, 0.5);
    font-size: 14px;
    cursor: pointer;
  }
  .Course-item {
    margin-bottom: 1.8rem;
  }
}
.content-heder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0 0;
  //  /deep/ .search {
  //   width: 20%;

  //   .el-input__inner {
  //     height: 32px;
  //     line-height: 32px;

  //     border: 1px solid #d4d4d4;
  //     border-right: none;
  //     background: #f5f5f5;
  //   }

  // }
}
</style>